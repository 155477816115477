import React from 'react';

export const youtubeIcon = () => {
  return (
    <svg width="26" height="20" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.9458 10.875L14.1358 7.875L8.9458 4.875V10.875ZM20.5058 3.045C20.6358 3.515 20.7258 4.145 20.7858 4.945C20.8558 5.745 20.8858 6.435 20.8858 7.035L20.9458 7.875C20.9458 10.065 20.7858 11.675 20.5058 12.705C20.2558 13.605 19.6758 14.185 18.7758 14.435C18.3058 14.565 17.4458 14.655 16.1258 14.715C14.8258 14.785 13.6358 14.815 12.5358 14.815L10.9458 14.875C6.7558 14.875 4.1458 14.715 3.1158 14.435C2.2158 14.185 1.6358 13.605 1.3858 12.705C1.2558 12.235 1.1658 11.605 1.1058 10.805C1.0358 10.005 1.0058 9.315 1.0058 8.715L0.945801 7.875C0.945801 5.685 1.1058 4.075 1.3858 3.045C1.6358 2.145 2.2158 1.565 3.1158 1.315C3.5858 1.185 4.4458 1.095 5.7658 1.035C7.0658 0.965 8.2558 0.935 9.3558 0.935L10.9458 0.875C15.1358 0.875 17.7458 1.035 18.7758 1.315C19.6758 1.565 20.2558 2.145 20.5058 3.045Z"
        fill="#FF0953"
      />
    </svg>
  );
};
