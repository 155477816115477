import React from 'react';

export const facebookIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.75 10.8999C20.75 5.3799 16.27 0.899902 10.75 0.899902C5.23 0.899902 0.75 5.3799 0.75 10.8999C0.75 15.7399 4.19 19.7699 8.75 20.6999V13.8999H6.75V10.8999H8.75V8.3999C8.75 6.4699 10.32 4.8999 12.25 4.8999H14.75V7.8999H12.75C12.2 7.8999 11.75 8.3499 11.75 8.8999V10.8999H14.75V13.8999H11.75V20.8499C16.8 20.3499 20.75 16.0899 20.75 10.8999Z"
        fill="#FF0953"
      />
    </svg>
  );
};
