import React from 'react';

export const instagramIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.1479 0.875H14.5479C17.7479 0.875 20.3479 3.475 20.3479 6.675V15.075C20.3479 16.6133 19.7368 18.0885 18.6491 19.1762C17.5614 20.2639 16.0862 20.875 14.5479 20.875H6.1479C2.9479 20.875 0.3479 18.275 0.3479 15.075V6.675C0.3479 5.13674 0.95897 3.66149 2.04668 2.57378C3.13439 1.48607 4.60964 0.875 6.1479 0.875ZM5.9479 2.875C4.99312 2.875 4.07745 3.25428 3.40232 3.92942C2.72719 4.60455 2.3479 5.52022 2.3479 6.475V15.275C2.3479 17.265 3.9579 18.875 5.9479 18.875H14.7479C15.7027 18.875 16.6184 18.4957 17.2935 17.8206C17.9686 17.1455 18.3479 16.2298 18.3479 15.275V6.475C18.3479 4.485 16.7379 2.875 14.7479 2.875H5.9479ZM15.5979 4.375C15.9294 4.375 16.2474 4.5067 16.4818 4.74112C16.7162 4.97554 16.8479 5.29348 16.8479 5.625C16.8479 5.95652 16.7162 6.27446 16.4818 6.50888C16.2474 6.7433 15.9294 6.875 15.5979 6.875C15.2664 6.875 14.9484 6.7433 14.714 6.50888C14.4796 6.27446 14.3479 5.95652 14.3479 5.625C14.3479 5.29348 14.4796 4.97554 14.714 4.74112C14.9484 4.5067 15.2664 4.375 15.5979 4.375ZM10.3479 5.875C11.674 5.875 12.9458 6.40178 13.8834 7.33947C14.8211 8.27715 15.3479 9.54892 15.3479 10.875C15.3479 12.2011 14.8211 13.4729 13.8834 14.4105C12.9458 15.3482 11.674 15.875 10.3479 15.875C9.02182 15.875 7.75005 15.3482 6.81237 14.4105C5.87468 13.4729 5.3479 12.2011 5.3479 10.875C5.3479 9.54892 5.87468 8.27715 6.81237 7.33947C7.75005 6.40178 9.02182 5.875 10.3479 5.875ZM10.3479 7.875C9.55225 7.875 8.78919 8.19107 8.22658 8.75368C7.66397 9.31629 7.3479 10.0794 7.3479 10.875C7.3479 11.6706 7.66397 12.4337 8.22658 12.9963C8.78919 13.5589 9.55225 13.875 10.3479 13.875C11.1435 13.875 11.9066 13.5589 12.4692 12.9963C13.0318 12.4337 13.3479 11.6706 13.3479 10.875C13.3479 10.0794 13.0318 9.31629 12.4692 8.75368C11.9066 8.19107 11.1435 7.875 10.3479 7.875Z"
        fill="#FF0953"
      />
    </svg>
  );
};
